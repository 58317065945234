import React from "react";
import Layout from "../containers/Layout";
import { Contact } from "../components";
import Jumbotron from "../components/CareerComponents/Jumbotron";
import CoreValues from "../components/HomeComponents/CoreValues";
import SupportedBy from "../components/HomeComponents/SupportedBy";
import Benefits from "../components/CareerComponents/Benefits";
import OpenPositions from "../components/CareerComponents/OpenPositions";

export default function Career() {
  return (
    <Layout title={"Career"}>
      <Jumbotron />
      <CoreValues />
      <Benefits />
      <OpenPositions />
      <Contact />
    </Layout>
  );
}
