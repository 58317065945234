import React, { Fragment, useState } from "react";
import * as Animate from "react-reveal";
import { openPositions } from "./data";
import { Dialog, Transition } from "@headlessui/react";

const Card = ({ position, handleApply }) => {
  return (
    <div className="relative rounded-lg flex flex-col justify-between award-card bg-white px-4 lg:px-6 py-6 lg:py-8">
      <div className=" bg-white h-[300px] md:h-[350px]">
        <img
          className="w-full h-full bg-white object-cover rounded-lg"
          src={position?.image}
          alt={position?.title}
        />
      </div>

      <div className="flex flex-col pt-4 lg:pt-6">
        <div className="mb-6 lg:mb-8 sm:h-20 flex flex-col justify-between">
          <h4 className="text-cstm-green lg:pr-2 font-semibold text-base md:text-lg lg:text-xl lg:leading-8 w-10/12">
            {position?.title}
          </h4>
          <p className="mt-2 text-cstm-grey">{position.jobType}</p>
        </div>
        <div className="block">
          <button
            onClick={() => handleApply(position.id)}
            className="w-full flex flex-row items-center justify-center text-sm md:text-sm-15 lg:text-base text-center rounded font-medium py-3 px-4 bg-cstm-green border border-cstm-green ease-in duration-300 hover:bg-cstm-green text-[#fff]">
            <span>Apply For This Position</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const OpenPositionModal = ({
  showPositionModal,
  handleClose,
  activePosition,
}) => {
  return (
    <Transition.Root show={showPositionModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        onClose={handleClose}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 mt-16 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div className="inline-block w-full align-center bg-white rounded-lg overflow-hidden text-left shadow-xl transform transition-all max-w-3xl">
              <div className="bg-[#ffffff] px-5 py-10">
                <h2 className="text-xl font-semibold">
                  {activePosition?.title}
                </h2>

                <div className="mt-8">
                  <h3 className="text-lg font-medium mb-3">About Reeddi</h3>
                  <div className="text-sm text-cstm-grey flex flex-col space-y-4">
                    <p>
                      We are a Climate-Technology Company on a mission to make
                      the future.
                    </p>
                    <p>
                      With uniquely researched innovations that address the
                      critical needs of our target market, we have designed and
                      built software and hardware solutions that address
                      challenges from reliable energy access to essential
                      equipment and machinery rentals.
                    </p>
                    <p>
                      The idea of making a positive impact with every innovation
                      drives us. Our products promote environmentally and
                      socially responsible practices and values. Our products
                      are Reeddi Capsule, Reeddi BigEnergy, and Tempown.
                    </p>
                  </div>
                </div>

                {activePosition.why && (
                  <div className="mt-8">
                    <h3 className="text-lg font-medium mb-3">
                      Why We Need You
                    </h3>
                    <p className="text-sm text-cstm-grey">
                      {activePosition?.why}
                    </p>
                    <ul className="text-sm text-cstm-grey flex flex-col space-y-4 mt-5 list-disc px-5">
                      {activePosition?.roles?.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                )}

                <div className="mt-8">
                  <h3 className="text-lg font-medium">Requirements</h3>
                  <ul className="text-sm text-cstm-grey flex flex-col space-y-4 mt-5 list-disc px-5">
                    {activePosition?.requirements?.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>

                <div className="mt-8">
                  <h3 className="text-lg font-medium mb-3">How To Apply?</h3>
                  <p className="text-sm text-cstm-grey">
                    Submit your Cover letter, Resume, and other details in the
                    link below:
                  </p>
                  <p className="text-sm text-black underline font-medium mt-2">
                    <a
                      target="_blank"
                      href="https://forms.gle/hmx8WitiPpRQTwedA">
                      Apply Here
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const OpenPositions = () => {
  const [activePosition, setActivePosition] = useState({});
  const [showPositionModal, setShowPositionModal] = useState(false);

  const handleApply = (id) => {
    setShowPositionModal(!showPositionModal);
    setActivePosition(openPositions[id - 1]);
  };

  return (
    <>
      <OpenPositionModal
        showPositionModal={showPositionModal}
        activePosition={activePosition}
        handleClose={() => setShowPositionModal(!setShowPositionModal)}
      />

      <section id="openPositions" className="relative">
        <div className="w-11/12 m-auto py-10 lg:pt-16 lg:pb-24  max-w-7xl">
          <div className="">
            <Animate.Fade bottom>
              <div className="flex-col gap-y-4">
                <h3 className="text-3xl font-semibold md:text-3xl lg:text-4xl xl:text-5xl">
                  Open Positions
                </h3>
                <p className="mt-3 text-cstm-grey text-base lg:text-lg">
                  Join Us And Let’s Make A Difference
                </p>
                <div className="mt-8 h-[2px] bg-cstm-green w-5/12 md:w-3/12" />
              </div>
            </Animate.Fade>
          </div>
          <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 xl:grid-cols-3 lg:gap-10 xl:gap-12">
            {openPositions?.map((position) => (
              <Card
                key={position?.id}
                position={position}
                handleApply={handleApply}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OpenPositions;
