import React from "react";
import Tag from "../Tag";
import * as Animate from "react-reveal";
import AwardImage from "../../assets/images/iaa/abstract-award.svg";
import HeroImage from "../../assets/images/iaa/jumbotron-image.webp";

export default function Jumbotron() {
  const handleScrollToOpenPosition = () => {
    document.getElementById("openPositions").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <main
      className="relative pt-14 md:pt-20  bg-cover bg-center"
      style={{
        backgroundImage: `url(${AwardImage})`,
        backgroundSize: "cover",
      }}>
      <div className="container px-4 md:px-6 xl:px-0 m-auto pt-8 pb-8">
        <div className="w-full pb-8">
          <div className="w-full mb-8 text-center md:w-10/12 lg:w-8/12 mx-auto">
            <Animate.Fade bottom>
              <Tag text="Career" />
              <h1 className="font-semibold mt-6 lg:mt-8 mb-6 sm:mb-4 leading-tight text-3xl md:text-4xl lg:text-5xl lg:leading-tight">
                Join Our Fast Growing Team.
              </h1>
              {/* <p className='text-base lg:text-lg lg:leading-8 mx-auto w-full md:w-11/12 lg:w-10/12 text-cstm-grey'>
                                Over 1 billion people presently live without electricity
                                in the world. To address this, Reeddi has built a portable and clean energy
                                solution at a price point individuals, households, and businesses can afford.
                            </p> */}
              <div className="flex justify-center mt-8 lg:mt-12">
                <button
                  onClick={handleScrollToOpenPosition}
                  className="bg-cstm-green py-4 px-6 lg:px-8 rounded-lg flex flex-row item-center text-white text-sm md:text-sm-15">
                  <span className="">See All Open Position</span>
                  <span className="pl-2 md:pl-3 pt-1">
                    <svg
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 7H13.5M13.5 7L7.5 1M13.5 7L7.5 13"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </Animate.Fade>
          </div>

          <div className="w-full mt-12 lg:mt-16">
            <Animate.Fade bottom>
              <img className="w-fit" src={HeroImage} alt="iaa" />
            </Animate.Fade>
          </div>
        </div>
      </div>
    </main>
  );
}
