import React from 'react'
import * as Animate from "react-reveal"
import CoinIcon from "../../assets/images/career/coin.svg"
import GlassesIcon from "../../assets/images/career/glasses.svg"
import MapIcon from "../../assets/images/career/map.svg"
import MoonsatIcon from "../../assets/images/career/moon-sat.svg"
import HeartIcon from "../../assets/images/career/heart.svg"

const Card = ({icon, text}) => {
    return <div className='companyBenefitCard px-4 py-6 lg:py-8 rounded-lg flex flex-row items-center space-x-4'>
    <div className='w-4 lg:w-6'>
        <img src={icon || CoinIcon} alt="Career Icon" />
    </div>
    <p className='text-sm-15 lg:text-base text-cstm-grey capitalize'>{text}</p>
</div>
}

const Benefits = () => {
    return (
        <section className="relative">
            <div className="w-11/12 m-auto py-2 lg:pt-0 lg:pb-24  max-w-7xl flex flex-wrap flex-row justify-between">
                <div className="w-full md:w-4/12">
                    <Animate.Fade bottom>
                        <div className="flex-col gap-y-4">
                            <h3 className="text-3xl font-semibold md:text-3xl lg:text-4xl xl:text-5xl">
                                Our Benefits
                            </h3>
                            <p className="mt-3 text-cstm-grey text-base lg:text-lg">
                                People First, Always
                            </p>
                            <div className='mt-8 h-[2px] bg-cstm-green w-9/12 md:w-8/12' />
                        </div>
                    </Animate.Fade>
                </div>
                <div className='w-full md:w-5/12 mt-12 md:mt-0 grid grid-cols-1 gap-6 lg:gap-6'>
                    <Card icon={CoinIcon} text="Competitive Salary and Meaningful Equity" />
                    <Card icon={GlassesIcon} text="Unlimited PTO and sick leave" />
                    <Card icon={MapIcon} text="Remote first and light meeting culture " />
                    <Card icon={MoonsatIcon} text="Annual company retreats " />
                    <Card icon={HeartIcon} text="A team that values diversity, equity and inclusion" />
                </div>
            </div>
        </section>
    )
}

export default Benefits