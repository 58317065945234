import Img1 from "../../assets/images/career/electrical-electronics-engineer.webp";
import Img2 from "../../assets/images/career/accountant.webp";

export const openPositions = [
  {
    id: 1,
    image: Img1,
    title: "Electrical Electronics Engineer",
    jobType: "Fulltime",
    why: "We are a company driven by innovation as we are in the business of making the future. We require a talented and  dedicated Electrical Electronic Engineer to join our team.  You will play a crucial role in designing, developing, and  implementing cutting-edge electrical and electronic systems  for our products. Your expertise in solar systems and  battery technology will be instrumental in ensuring the  reliability and performance of our sustainable energy  solutions.",
    requirements: [
      "Bachelor's degree in Electrical Engineering, Electronic Engineering, or a related field.",
      "Strong knowledge and understanding of electrical and electronic engineering principles, with a focus on solar systems and battery technology.",
      "Proficiency in using electrical design and simulation software (e.g., CAD, SPICE) for solar and battery-related applications.",
      "Experience with circuit design, schematic capture, and PCB layout, specifically in the context of solar and battery systems.",
      "Familiarity with microcontrollers, embedded systems, and digital signal processing, with a specific emphasis on their integration with solar and battery technologies.",
      "Knowledge of power electronics, analog and digital circuit design, and signal integrity, with application to solar systems and battery technology.",
      "Understanding of sustainable energy solutions, particularly solar energy generation, energy storage, and battery management systems.",
      "Strong problem-solving skills and attention to detail, especially when dealing with solar and battery-related challenges.",
      "Excellent written and verbal communication skills.",
      "Ability to work effectively in a team-oriented, collaborative environment.",
      "Experience with software programming and firmware development is a plus.",
    ],
    roles: [
      "Design and develop electrical and electronic systems for Reeddi's software and hardware products, with a focus on solar systems and battery technology.",
      "Collaborate with cross-functional teams to integrate electrical and electronic components, including solar panels, inverters, batteries, and energy management systems, into overall product design.",
      "Conduct testing and verification of electrical and electronic systems to ensure compliance with specifications and standards, specifically related to solar and battery technologies.",
      "Troubleshoot and resolve electrical and electronic issues during product development and production phases, with a specific emphasis on solar systems and battery technology.",
      "Stay up-to-date with industry trends and advancements in electrical and electronic engineering, particularly in the field of sustainable energy solutions.",
      "Assist in the creation and review of technical documentation, including specifications, schematics, and user manuals, for solar and battery-related systems.",
      "Support manufacturing and production teams in the implementation and testing of electrical and electronic systems, with a focus on solar systems and battery technology.",
      "Collaborate with external partners and suppliers to ensure the availability of high-quality components and technologies related to solar and battery systems.",
      "Ensure compliance with relevant safety and regulatory standards, specifically pertaining to solar systems and battery technology.",
    ],
    link: "https://docs.google.com/forms/d/1KqMMYlYlDgIq9D7BQJIOZVN9hl4seCm3Hu31m_ONhD0/edit?ts=64abd7e8",
    image: Img1,
    title: "Electrical Electronics Engineer",
    jobType: "Fulltime",
    link: "",
  },
  {
    id: 2,
    image: Img2,
    title: "Accountant",
    jobType: "Fulltime",
    requirements: [
      "Must know how to prepare budgets, disburse and manage funds, maintain records and submit financial reports.",
      "Must have a Bachelor's degree in Accounting, Tax, or Finance and 1-2 years of experience in Accounting/Corporate Finance.",
      "Must posses data-entry, reporting and research skills.",
      "Must be detail-oriented with excellent time management skills.",
    ],
    link: "https://bit.ly/ReeddiRecruitment",
    image: Img2,
    title: "Accountant",
    jobType: "Fulltime",
    link: "",
  },
];
